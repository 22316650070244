 <template>

  <div>

    <v-alert dense text type="error" :value="alertDisplay">{{ alertMsg }}</v-alert>

    <v-data-table
    :headers    = "headers"
    :items      = "users"
     sort-by    = "name"
     class       = "mb-2 elevation-1"
    :loading      = "myloadingvariable"
    loading-text ="Loading... Please wait"
    >

    <template v-slot:item.manageUsers="{ item }">
        <v-checkbox dense
          v-model="item.manageUsers"
          disabled
        ></v-checkbox>
    </template>

    <template v-slot:item.forms="{ item }">
        <span>{{ formatFormArray( item ) }}</span>
    </template>
  
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Users</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
     
        <v-dialog  v-model="dialog" max-width="500px" >

          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            > New User
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                 <v-row dense>

                  <v-col cols="12" sm="6">
                    <v-text-field dense outlined
                      v-model="editedItem.sales_id"
                      label="User Id"
                    ></v-text-field>
                  </v-col>

                  <v-col v-if= "isNewUser === true" cols="12" sm="6">
                    <v-text-field dense outlined
                      v-model="editedItem.password"
                      label="User Password"
                      autocomplete="new-password"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field dense outlined
                      v-model="editedItem.name"
                      label="User Name"
                      autocomplete="username"
                    ></v-text-field>
                  </v-col>


                  <v-col cols="12" sm="6">
                    <v-text-field dense outlined
                      v-model="editedItem.email"
                      label="User Email"
                    ></v-text-field>
                  </v-col>

                  <!-- need to add a combo box -->
                  <v-col cols="12" >

                    <v-combobox
                      v-model =  "editedItem.forms"
                      :items  =  "formItems"
                      label   =  "Forms"
                      clearable
                      multiple
                      outlined
                      small-chips
                      deletable-chips
                      hide-selected
                    ></v-combobox>    

                  </v-col>

                  <v-col cols="12">
                  <v-checkbox
                    v-model="editedItem.manageUsers"
                    label="Manage Users"
                   ></v-checkbox>
                  </v-col>

                </v-row>
              </v-container>
        
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  color="blue darken-1"  text  @click="close">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn  color="red darken-1"   text  @click="save">Save</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>

              </v-card-text>

   
          </v-card>
        </v-dialog>
      
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>

           <v-card-text>
               <div class="text-center text-h6 text--primary" ><strong>Confirm Account Deletion ?</strong></div>
               <p   class="text-center text-h6">`{{ selectedEngineer }}( {{ editedItem.sales_id }} )`</p>
           </v-card-text>
      
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-spacer></v-spacer>
                <v-btn color="red darken-1"  text @click="deleteItemConfirm">Delete</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>

          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogPassword" max-width="500px">
          <v-card>

            <v-container fluid>
         
               <div class="text-center text-h6 text--primary" ><strong>Update Password </strong></div>
               <div class="text-center text-h6">`{{ editedItem.name }}( {{ editedItem.sales_id }} )`</div>
               <br>
         
                <v-row style="align-items: baseline;" class="d-flex">
                    <v-spacer></v-spacer>
                    <v-col cols="8">
                      <v-text-field
                        v-model="editedItem.password"
                        autocomplete="new-password"
                        label="New Password"
                        value=""
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>

                </v-row>
            </v-container>

            <v-card-actions>
              <v-spacer></v-spacer>
               <v-btn color="blue darken-1" text @click="passwordEditClose">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1"  text @click="passwordEditConfirmed">Update</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>

          </v-card>
        </v-dialog>

      </v-toolbar>
    </template>

    <template v-slot:item.actions="{ item }">

       <div id="actionIcons" class="d-flex">    
       <v-icon color="primary"       id="accountEditIcon"     class="mr-2" @click="editItem(item)">mdi-account-edit</v-icon>
       <v-icon color="secondary"     id="accountPasswordIcon" class="mr-2" @click="passwordEdit(item)">mdi-lock-reset</v-icon>
       <v-icon color="red"           id="accountDeleteIcon"   @click="deleteItem(item)">mdi-delete-alert</v-icon>
       </div>    

    </template>

    </v-data-table>

  </div>

</template>

<script>

  export default {
      name       : 'userManagement',
      data: () => ({

         formItems: [
          {text: 'All',                       value:  "all",                 disabled:false},
          {text: 'Re-branding',               value:  "reBrand",             disabled:false},
          {text: 'Test Certificate',          value:  "testCertificate",     disabled:false},
          {text: 'Enhanced Test',             value:  "enhancedTest",        disabled:false},
          {text: 'Annual Liquid Inspection',  value:  "annualInspection",    disabled:false},
          {text: 'Job Sheet',                 value:  "jobSheet",            disabled:false},
          {text: 'Self Owned Commercial',     value:  "selfOwnedCommercial", disabled:false},
          {text: 'Restrict Override',         value:  "restrictOverride",    disabled:false},
          
        ],
      
          alertDisplay      :  false,
          alertMsg          :  '',
          
          dialog            : false,
          dialogDelete      : false,
          dialogPassword    : false,
          
          myloadingvariable : false,

          headers: [
            { text: 'User Id',        value: 'sales_id', align: 'start' },
            { text: 'Name',           value: 'name' },
            { text: 'Email',          value: 'email' },
            { text: 'Manage Users',   value: 'manageUsers' },
            { text: 'Forms',          value: 'forms' },
            { text: 'Actions',        value: 'actions', sortable: false },
          ],
          users             : [],
          editedIndex       : -1,
          editedItem        : {
            id           : '',
            sales_id     : '',
            name         : '',
            email        : '',
            manageUsers  : false,
            forms        : [],
            password     : '',

          },
          defaultItem       : {
            id           : '',
            sales_id     : '',
            name         : '',
            email        : '',
            manageUsers  : false,
            forms        : [],
            password     : '',
          },

      }),
      computed: {
          formTitle () {
              return this.editedIndex === -1 ? 'New User' : 'Edit User'
          },
          selectedEngineer () {
              return this.editedIndex === -1 ? null : this.editedItem.name
          },
          isNewUser () {
               return this.editedIndex === -1 ? true : false
          },
          
      },

      watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
      },

      created () {
        this.initialize()
      },

      methods: {
        formatFormArray( item ){
        
          try {
              var   forms      = ""
              item.forms.forEach(function(i, idx, array){
                forms = forms + `${i.text}` 
                if (idx != array.length - 1){forms = forms + " , "}
              });
        
          } catch (error ){
              console.log(error)
          } finally {
              return forms;
          }
    
      },

        initialize () {

                  var self               = this;
                  this.myloadingvariable = true; 
                  this.$http.post( this.$restServer.readUserURL ,  {} )
                        .then(  (response) => {
                        console.log( response.data);
                        self.myloadingvariable = false; 
                         if ( !response.data['exception'] ) {
                             self.users   = response.data['users'];
                        } else {
                             self.showAlert( response.data['exception'] );
                        }
                  })
                  .catch( (e)  => { 
                       self.users = [];
                       console.log(e);
                       self.showAlert( e );
                  });
        },

        showAlert ( msg ) {
              this.alertDisplay      = true; 
              this.alertMsg          = msg; 
              setTimeout( () => { 
                this.alertDisplay      = false
              }, 3000);
        },

        editItem (item) {
           this.editedIndex = this.users.indexOf(item)
           this.editedItem  = Object.assign({}, item)
           this.dialog      = true
        },

        deleteItem (item) {

          this.editedIndex   = this.users.indexOf(item)
          this.editedItem    = Object.assign({}, item)
          this.dialogDelete  = true
        },

        deleteItemConfirm () {

          // need to send this deleted user to the server and also delete locally from array
            console.log("User Deleted");
            console.log(this.editedItem);
 
            var self = this;
            this.$http.post( this.$restServer.deleteUserURL , this.editedItem)
            .then(  (response) => {
                    console.log( response.data);
                    if ( !response.data['exception'] ) {
                           self.initialize();
                           self.closeDelete()
                    } else {
                        self.showAlert( response.data['exception'] );
                    }
            }).catch( (e) => { 
                  console.log(e);
                  self.showAlert( e );
            });
        },

        passwordEdit (item) {
          this.editedIndex              = this.users.indexOf( item )
          this.editedItem               = Object.assign({}, item)
          this.editedItem['password']   = "";
          this.dialogPassword           = true
        },

        passwordEditClose() {
          this.dialogPassword = false
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
   
        },

        passwordEditConfirmed() {

            console.log("Password Edit Confirmed");
            console.log(this.editedItem);
 
            var self = this;
            this.$http.post( this.$restServer.updatePasswordURL , this.editedItem)
            .then(  (response) => {
                    
                    console.log( response.data);
                    if ( !response.data['exception'] ) {
                           self.initialize();
                           self.passwordEditClose()
                    } else {
                          self.showAlert( response.data['exception'] );
                    }

            }).catch( (e) => { 
                  console.log(e);
                  self.showAlert( e );
            });
        },

        close () {
          this.dialog = false
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },

        closeDelete () {
          this.dialogDelete = false
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },

        save () {

          if (this.editedIndex > -1) {
 
            console.log("User edited");
            console.log(this.editedItem);

            var self = this;
            this.$http.post( this.$restServer.updateUserURL , this.editedItem)
            .then(  (response) => {
                    
                    console.log( response.data);
                    if ( !response.data['exception'] ) {
                           self.initialize();
                           self.close()
                    } else {
                          self.showAlert( response.data['exception'] );
                    }

            }).catch( (e) => { 
                  console.log(e);
                  self.showAlert( e );
            });
   
          } else {

            // need to send this new user to the server and then also push onto current array of users
            // this.users.push(this.editedItem)
            console.log("User created");
            console.log(this.editedItem);

            var self = this;
            this.$http.post( this.$restServer.createUserURL , this.editedItem)
            .then(  (response) => {

                    console.log( response.data);
                    if ( !response.data['exception'] ) {
                           self.initialize();
                           self.close()
                    } else {
                          self.showAlert( response.data['exception'] );
                    }
            
            }).catch( (e) => { 
                  console.log(e);
                  self.showAlert( e );
            });

          }
        },

      },
  }
</script>


<style scoped>
</style>